import React, { useEffect, useState } from "react";
import { Checkbox, DialogContent, FormControlLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';

//import moment from "moment";
import { dataService } from "services/data-service";
import { checkEmptyValidation } from "services/validation-service";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FilledInput from "@mui/material/FilledInput";
import MessageBar from "components/MessageBar/MessageBar";
import { CustomButton } from "components/Common/CustomButton";
import moment from "moment";
import HoldReasonDialog from "./HoldReasonDialog";
//import { StartFinishViewData } from "components/Common/StartFinishViewData/StartFinishViewData";
//import { currentUser } from "services/user-service";

function OrderFlowStepDialog(props) {
    const { onClose, open, Order, WorkCenterId, IsStart, setIsReload, isReload } = props;
    const [orderData, setOrderData] = useState({})
    const [machineList, setMachineList] = useState([]);
    const [operatorList, setOperatorList] = useState([]);
    const [showSubmitLoading, setShowSubmitLoading] = useState(false); // loading page state value
    const [messageDialoge, setMessageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });
    const [openHoldReason, setOpenHoldReason] = useState(false)
    // Usef defined states

    const [flowStepValidateModel, setFlowStepValidateModel] = useState({

        ProductId: { message: '', touched: false },
        CustomerId: { message: '', touched: false },
        Date: { message: '', touched: false },
        ProductFileId: { message: '', touched: false },
        Quantity: { message: '', touched: false },
        UnitPrice: { message: '', touched: false },
        IsValidationError: false
    });

    const setFlowStepModelData = (flowData) => {
        let flowStepModel = {
            IsStart: IsStart || true,
            FlowStepId: Order?.FlowStepId,
            OrderId: Order?.OrderId,
            WorkCenterId: WorkCenterId || 0,
            Quantity: Order?.Quantity || null,
            OriginalInQuantity: Order?.Quantity || null,
            TransportDetail: null,
            MachineId: null,
            OperatorId: null,
            MachineIds: [],
            OperatorIds: [],
            Remarks: null
        }
        return flowStepModel;
    }

    const [flowStepModel, setFlowStepModel] = useState(setFlowStepModelData(null));

    const handleClose = () => {
        onClose(false);
    };

    useEffect(() => {
        fetchData()
    }, [Order])

    const fetchData = async () => {
        let res = await dataService('get', `OrderOperatorMachineList/${Order?.FlowStepId}`, '');

        let machineOperatorArray = res?.data
        const MachineValues = machineOperatorArray.filter((thing, i, arr) => {
            return arr.indexOf(arr.find(t => Number(t.MachineId) === Number(thing.MachineId))) === i;
        });

        setMachineList(MachineValues);


        const OperatorValues = machineOperatorArray.filter((thing, i, arr) => {
            return arr.indexOf(arr.find(t => Number(t.OperatorId) === Number(thing.OperatorId))) === i;
        });


        setOperatorList(OperatorValues);

        let resOrderFlowStep = await dataService('get', `OrderFlowStartFinishGet/${Order?.FlowStepId}`, '');
        if (resOrderFlowStep) {
            setOrderData(resOrderFlowStep?.data);
            let resOrderFlowStepData = resOrderFlowStep?.data
            var selMachinIds = resOrderFlowStepData?.MachineId?.split(',')?.map(Number) || [];
            var selOperatorIds = resOrderFlowStepData?.OperatorId?.split(',')?.map(Number) || [];

            setFlowStepModel({ ...flowStepModel, Quantity: resOrderFlowStepData?.QuantityIn || Order?.Quantity, OriginalInQuantity: resOrderFlowStepData?.QuantityIn || Order?.Quantity, MachineIds: selMachinIds, OperatorIds: selOperatorIds, Remarks: resOrderFlowStepData?.Remarks });

            // if(resOrderFlowStepData?.QuantityIn){
            //     setFlowStepModel({ ...flowStepModel, Quantity: resOrderFlowStepData?.QuantityIn });
            // }

        }
    }

    const handleChange = (evt, type, checkValidation) => {

        setFlowStepModel({ ...flowStepModel, [evt.target.name]: evt.target.value });
        if (checkValidation) {
            if (evt.target.name === 'Quantity') {
                setFlowStepModel({ ...flowStepModel, [evt.target.name]: evt.target.value });
                if (evt.target.value === "") {
                    setFlowStepValidateModel({ ...flowStepValidateModel, [evt.target.name]: { message: checkEmptyValidation(evt.target.value, evt.target.name), touched: true } });
                }
                else {
                    //setFlowStepValidateModel({ ...flowStepValidateModel, [evt.target.name]: { message: "", touched: true } });
                    let iWorkCenterId = parseInt(flowStepModel?.WorkCenterId);
                    // Allow update quantity till cutting work center. (Cutting work center ID is 4)
                    if (evt.target.value > flowStepModel.OriginalInQuantity && iWorkCenterId > 4) {
                        setFlowStepValidateModel({ ...flowStepValidateModel, [evt.target.name]: { message: "Quantity can not be greater then In Quantity", touched: true } });
                    }
                    else {
                        setFlowStepValidateModel({ ...flowStepValidateModel, [evt.target.name]: { message: "", touched: true } });
                    }
                }
            }
            else {
                setFlowStepValidateModel({ ...flowStepValidateModel, [evt.target.name]: { message: checkEmptyValidation(evt.target.value, evt.target.name), touched: true } });
            }
        }
    };

    const handleCheckBoxes = (evt) => {

        if (evt.target.name === 'OperatorIds') {
            if (evt.target.checked) {
                let Oids = flowStepModel?.OperatorIds;
                Oids.push(parseInt(evt.target.value || 0));
                setFlowStepModel({ ...flowStepModel, OperatorIds: Oids });
            }
            else {
                let Oids = flowStepModel?.OperatorIds;
                Oids = Oids.filter(item => item !== parseInt(evt.target.value || 0))
                setFlowStepModel({ ...flowStepModel, OperatorIds: Oids });
            }
        }
        else if (evt.target.name === 'MachineIds') {
            if (evt.target.checked) {
                let Mids = flowStepModel?.MachineIds;
                Mids.push(parseInt(evt.target.value || 0));
                setFlowStepModel({ ...flowStepModel, MachineIds: Mids });
            }
            else {
                let Mids = flowStepModel?.MachineIds;
                Mids = Mids.filter(item => item !== parseInt(evt.target.value || 0))
                setFlowStepModel({ ...flowStepModel, MachineIds: Mids });
            }
        }
        //setFlowStepModel({ ...orderModel, [evt.target.name]: evt.target.checked });
    }

    const handleSubmitOrder = async () => {
        if (Order?.noReasonHoldOrders?.length > 0) {
            setOpenHoldReason(true)
        } else {
            let reqData = { ...flowStepModel };
            reqData.IsStart = IsStart;

            if (!checkValidations()) {

                setShowSubmitLoading(true);
                reqData.OperatorIds = reqData?.OperatorIds?.join(','); //Convert to comma seperated values 
                reqData.MachineIds = reqData?.MachineIds?.join(','); //Convert to comma seperated values 
                reqData.Quantity = parseInt(reqData.Quantity || 0);
                reqData.Device = null
                let res = await dataService('post', `OrderFlowStartFinish/${Order?.OrderId || 0}/${flowStepModel?.FlowStepId || 0}`, reqData);
                setShowSubmitLoading(false);
                if (res && (res.status === 200 || res.status === 304)) {
                    setMessageDialoge({ show: true, type: 'success', message: flowStepModel?.FlowStepId ? 'Order updated successfully!' : 'Order registered successfully!' });
                    setIsReload(!isReload)
                    handleClose();

                } else {
                    //setOpen(res.message);
                    setMessageDialoge({ show: true, type: 'error', message: res?.message || 'Error occured please try again later' });
                }
            }
        }
    }


    const checkValidations = () => {

        let iWorkCenterId = parseInt(flowStepModel?.WorkCenterId);
        // Allow update quantity till cutting work center. (Cutting work center ID is 4)

        let validateErrors = {
            Quantity: { message: flowStepModel.Quantity === "" ? checkEmptyValidation(flowStepModel.Quantity, 'Quantity') : ((flowStepModel.Quantity > flowStepModel.OriginalInQuantity && iWorkCenterId > 4) ? "Quantity can not be greater then In Quantity" : ""), touched: true }
            //Quantity: { message: flowStepModel.Quantity === "" ? checkEmptyValidation(flowStepModel.Quantity, 'Quantity') : "", touched: true }
        }
        if (validateErrors.Quantity.message !== '') {
            validateErrors.IsValidationError = true;
        }
        else {
            validateErrors.IsValidationError = false;
        }
        setFlowStepValidateModel(validateErrors);
        return validateErrors.IsValidationError;

    }

    return (
        <Dialog className="" onClose={handleClose} fullWidth={true} maxWidth="lg" aria-labelledby="simple-dialog-title" disableBackdropClick={true} open={open}>
            <div className={`${IsStart ? 'bg-green-600 border-green-600' : 'bg-red-800 border-red-800'} border-2 shadow sm:rounded-t rounded-md flex flex-wrap items-center justify-between`}>
                <DialogTitle className="pb-1 w-full" >
                    <div className="sm:grid sm:grid-cols-2 items-center">
                        <div className="text-gray-100">
                            <Box
                                justifyContent="flex-start"
                                display="flex"
                                flexWrap="wrap"
                            >
                                <CustomButton
                                    loading={showSubmitLoading}
                                    onClick={handleSubmitOrder}
                                    label={<span className="text-xl">{IsStart ? 'Start ' : 'Finish '}</span>}
                                    className={`${IsStart ? 'bg-gray-200 text-green-600' : 'bg-gray-200 text-red-600'} px-5 border-none mr-3`}
                                />
                                <span className="text-xl mt-4">{Order?.WorkCenterName}</span>
                            </Box>
                            <br />
                        </div>
                        <div className="text-gray-100 flex flex-wrap justify-end">
                            <div className="mr-10">
                                <span className="text-base block mb-1">  <strong> Order #: </strong>{Order?.OrderNumber}</span>
                                <span className="text-sm flex flex-wrap justify-start items-center">
                                    <strong>Total Quantity: </strong>
                                    <span className="px-2 py-1 text-sm font-bold bg-red-500 text-white rounded mr-3 ml-1"> {Order?.Quantity} </span>
                                    <strong>In Quantity: </strong>
                                    <span className="px-2 py-1 text-sm font-bold bg-red-500 text-white rounded ml-1">{flowStepModel.OriginalInQuantity}</span></span>
                                {/* <span className="text-xs">  [In Quantity: {flowStepModel.Quantity}]</span> */}
                            </div>
                        </div>
                    </div>

                </DialogTitle>
                <Close className="w-6 h-auto mr-2 cursor-pointer text-gray-100 absolute right-0 top-2" onClick={handleClose} />
            </div>

            <DialogContent className={`${IsStart ? "border-green-600" : "border-red-800"} border-2`}>

                <div className="overflow-hidden">
                    <div>
                        <dl>
                            <Grid item xs={12} lg={12}>
                                <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />

                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <div className="bg-gray-300 shadow overflow-hidden sm:rounded-lg border border-blue-500">
                                    <div className="">
                                        <dl>
                                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Product</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-blue-300">{Order?.ProductName}</dd>
                                                <dt className="text-sm font-medium text-gray-500">Customer</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-blue-300">{Order?.CustomerName}</dd>
                                                <dt className="text-sm font-medium text-gray-500">Client</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{Order?.Client}</dd>
                                            </div>

                                            <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-6 sm:gap-2 sm:px-6">
                                                {/* <dt className="text-sm font-medium text-gray-500">Order Date</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-blue-300">{moment(Order?.OrderDate).format('DD/MM/YYYY')}</dd> */}
                                                <dt className="text-sm font-medium text-gray-500">Delivery Date</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-blue-300">{moment(Order?.DeliveryDate).format('DD/MM/YYYY')}</dd>
                                                <dt className="text-sm font-medium text-gray-500">File Name</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 md:border-r border-blue-300">{Order?.FileName}</dd>
                                                <dt className="text-sm font-medium text-gray-500">File Size</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{Order?.FileSize}</dd>
                                            </div>
                                            {Order?.StartDate ?
                                                <div className="bg-gray-50 px-4 py-3 flex sm:flex-row flex-col sm:gap-2 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-500">Start Date:</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{moment(Order?.StartDate).format('DD/MM/YYYY HH:mm:ss A')}</dd>
                                                </div>
                                                : null}
                                        </dl>
                                    </div>
                                </div>

                                {/* <StartFinishViewData WorkCenter={Order?.WorkCenterName} OrderData={orderData} /> */}
                            </Grid>
                            {!IsStart &&
                                <Grid item xs={12} lg={12}>
                                    <div className="py-3 sm:grid sm:grid-cols-2 sm:gap-4">

                                        <Grid item xs={12} lg={10} className="px-0">
                                            <FormGroup className="mb-0">
                                                <FormLabel>{IsStart ? 'Start Quantity' : 'Finish Quantity'}</FormLabel>
                                                <TextField
                                                    variant="filled"
                                                    name="Quantity"
                                                    component={Box}
                                                    type="number"
                                                    width="100%"
                                                    marginBottom="1rem!important"
                                                    autoComplete="off"
                                                    value={flowStepModel.Quantity}
                                                    onChange={e => handleChange(e, null, true)}
                                                    error={flowStepValidateModel.Quantity.message !== '' && flowStepValidateModel.Quantity.touched}
                                                    helperText={flowStepValidateModel.Quantity.message !== '' && flowStepValidateModel.Quantity.touched ? flowStepValidateModel.Quantity.message : ''}
                                                >
                                                    <Box
                                                        paddingLeft="0.75rem"
                                                        paddingRight="0.75rem"
                                                        component={FilledInput}
                                                        autoComplete="off"
                                                        type="text"
                                                    />
                                                </TextField>
                                            </FormGroup>
                                        </Grid>
                                    </div>
                                </Grid>
                            }


                            {(machineList?.length > 0 || operatorList?.length > 0) &&

                                <div className="border-gray-100 border-2 shadow sm:rounded-lg sm:grid sm:grid-cols-2 mt-5">
                                    <div className="border-gray-100 border-r-4">
                                        <div className="px-4 py-2 sm:px-6 bg-gray-100">
                                            <h3 className="text-base  text-center font-bold leading-6 text-gray-900">Machine</h3>
                                        </div>
                                        <Grid item xs={12} lg={6}>
                                            <FormGroup>
                                                {machineList.map((machineData) => (
                                                    <FormControlLabel
                                                        key={machineData?.MachineId}
                                                        value="end"
                                                        control={<Checkbox
                                                            color="primary"
                                                            className="py-1"
                                                            checked={flowStepModel?.MachineIds.includes(machineData?.MachineId)}
                                                            value={machineData?.MachineId}
                                                            name="MachineIds"
                                                            onChange={handleCheckBoxes} />}
                                                        label={machineData?.MachineName}
                                                        labelPlacement="end" />
                                                ))
                                                }
                                            </FormGroup>

                                        </Grid>
                                    </div>

                                    <div>
                                        <div className="px-4 py-2 sm:px-6 bg-gray-100">
                                            <h3 className="text-base text-center font-bold leading-6 text-gray-900">Operator</h3>
                                        </div>
                                        <Grid item xs={12} lg={6}>
                                            <FormGroup>
                                                {operatorList.map((operatorData) => (
                                                    <FormControlLabel
                                                        value={operatorData?.OperatorId}
                                                        className="text-sm"
                                                        name="OperatorIds"
                                                        control={<Checkbox
                                                            color="primary"
                                                            className="py-1"
                                                            checked={flowStepModel?.OperatorIds.includes(operatorData?.OperatorId)}
                                                            value={operatorData?.OperatorId}
                                                            name="OperatorIds"
                                                            onChange={handleCheckBoxes} />}
                                                        label={operatorData?.OperatorName}
                                                        labelPlacement="end" />
                                                ))
                                                }
                                            </FormGroup>
                                        </Grid>
                                    </div>
                                </div>
                            }

                            {Order?.WorkCenterName === 'Delivery' && !IsStart &&
                                <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-6 items-center">
                                    <div>
                                        <FormGroup className="mb-0">
                                            <FormLabel>Transport Detail</FormLabel>
                                            <TextField
                                                variant="filled"
                                                name="TransportDetail"
                                                component={Box}
                                                type="number"
                                                width="100%"
                                                marginBottom="1rem!important"
                                                autoComplete="off"
                                                multiline
                                                minRows={2}
                                                maxRows={6}
                                                value={flowStepModel.TransportDetail}
                                                onChange={e => handleChange(e, null, true)}
                                            >
                                                <Box
                                                    paddingLeft="0.75rem"
                                                    paddingRight="0.75rem"
                                                    component={FilledInput}
                                                    autoComplete="off"
                                                    type="text"
                                                />
                                            </TextField>
                                        </FormGroup>
                                    </div>
                                </div>
                            }


                            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-6 items-center">
                                <div>

                                    <FormGroup className="mb-0">
                                        <FormLabel>Remarks</FormLabel>
                                        <TextField
                                            variant="filled"
                                            name="Remarks"
                                            component={Box}
                                            type="number"
                                            width="100%"
                                            marginBottom="1rem!important"
                                            autoComplete="off"
                                            multiline
                                            minRows={2}
                                            maxRows={6}
                                            value={flowStepModel.Remarks}
                                            onChange={e => handleChange(e, null, true)}
                                        >
                                            <Box
                                                paddingLeft="0.75rem"
                                                paddingRight="0.75rem"
                                                component={FilledInput}
                                                autoComplete="off"
                                                type="text"
                                            />
                                        </TextField>
                                    </FormGroup>
                                </div>
                                <div className="sm:col-span-2">
                                    <Box
                                        justifyContent="flex-start"
                                        display="flex"
                                        flexWrap="wrap"
                                    >
                                        <CustomButton
                                            loading={showSubmitLoading}
                                            onClick={handleSubmitOrder}
                                            label={IsStart ? 'Start' : 'Finish'}
                                            className={`${IsStart ? 'bg-green-600' : 'bg-red-800'} px-16 mt-1 text-white border-none`}
                                        />
                                    </Box>

                                </div>
                            </div>
                        </dl>
                    </div>
                </div>

            </DialogContent>
            {openHoldReason &&
                <HoldReasonDialog open={openHoldReason} onClose={setOpenHoldReason} Order={Order?.noReasonHoldOrders} />
            }
        </Dialog>
    );
};
export default OrderFlowStepDialog;