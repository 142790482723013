import React, { useEffect, useRef, useState } from "react";
import { DialogContent, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import moment from "moment";
import { dataService } from "services/data-service";
import { CustomButton } from "components/Common/CustomButton";
import "assets/css/print-order-form.css";
import PrintOrderFormFile from "./PrintOrderFormFile";
import PrintOrderFormShadeCard from "./PrintOrderFormShadeCard";
import PrintOrderFormHeader from "./PrintOrderFormHeader";
import PrintOrderFormEcatalogue from "./PrintOrderFormECatalogue";
import ProductBGColors from "components/Common/ProductBGColors";
import Checkbox from '@mui/material/Checkbox';

import QRCodeStyling from 'qr-code-styling';

function PrintOrderForm(props) {
    const { onClose, open, OrderId } = props;
    const [orderData, setOrderData] = useState();
    const [isBGColor, setIsBGColor] = useState(false);


    const handleClose = () => {
        onClose(false);
    };

    const renderSection = () => {
        switch (orderData?.ProductName) {
            case 'Header':
                return <PrintOrderFormHeader orderData={orderData} />;
            case 'Shade':
                return <PrintOrderFormShadeCard orderData={orderData} />;
            case 'E-Catalogue':
                return <PrintOrderFormEcatalogue orderData={orderData} />;
            default:
                return <PrintOrderFormFile orderData={orderData} />;
        }
    }

    useEffect(() => {
        fetchData()
    }, [OrderId])

    const fetchData = async () => {
        let res = await dataService('get', `OrderGet/${OrderId}`, '');
        if (res?.data) {
            res.data.PhotosPage = res?.data?.PhotosPage ? res?.data?.PhotosPage?.split(',')?.map(Number) : [];
            res.data.FittingNumber = res?.data?.FittingNumber ? res?.data?.FittingNumber?.split(',')?.map(Number) : [];
        }
        setOrderData(res?.data);
    }

    return (
        <Dialog onClose={handleClose} fullScreen={true} maxWidth="lg" aria-labelledby="simple-dialog-title" open={open}>
            <DialogContent>

                <Grid className={`w-full flex align-center justify-center no-print mb-2`}>
                    <CustomButton className='bg-blue-400 mt-1 text-white no-print text-center mr-1' size="small" label="Print" onClick={(e) => {
                        document.title = `RajSampler-JOB${orderData?.OrderId}-${moment(new Date()).format('DD-MM-YYYY')}`;
                        window.print();
                        document.title = process.env.REACT_APP_TITLE;
                    }} />
                    <CustomButton className='bg-blue-400 mt-1 text-white no-print text-center mr-1' size="small" label="Cancel" onClick={handleClose} />
                    <span className="ml-1 mt-2">
                        <Checkbox size={'small'} checked={isBGColor} onChange={(evt) => { setIsBGColor(evt.target.checked) }} name="isBGColor" className="p-0" color="primary" />
                        <span className="ml-1">Background Color</span>
                    </span>
                </Grid>
                <div className={`max-w-3xl mx-auto print-exact-color print-order bg-${isBGColor ? ProductBGColors[orderData?.ProductName] : 'white'}`}>
                    <div className="w-full text-center">
                        <strong><u>ORDER FORM</u></strong>
                    </div>
                    <div className="w-full">
                        <div className="grid grid-cols-12 items-center gap-2">
                            <div className="col-span-6 leading-none">
                                <p><small>
                                    <strong>Address:</strong>178/1/B, Phase-1, Near Ingersoll Rand, GIDC,<br />
                                    Naroda, Ahmedabad - 382330, Gujarat <br />
                                    <strong>Phone:</strong> +91 9898554003 <br />
                                    <strong>Email:</strong> raj.sampler@gmail.com <br />
                                    <strong>Website:</strong> www.rajsampler.com
                                </small>
                                </p>
                            </div>
                            <div className="col-span-1">
                                <img width={40} alt={orderData?.ProductName} src={`/img/${orderData?.ProductName?.toLowerCase()}_type.png`} />
                            </div>
                            <div className="col-span-3 justify-end">
                                <QrCode open={open} orderId={OrderId} />
                            </div>
                            <div className="col-span-2">
                                <img width={250} alt="raj-sampler" src='/img/rajsampler-logo.png' />
                            </div>
                        </div>
                    </div>
                    <div className="w-full -mt-3 border-t border-slate-200 text-sm">
                        <div className="grid grid-cols-12 gap-y-8 border-b border-slate-200">
                            <div className="col-span-7 py-0">
                                Customer: <strong>{orderData?.CustomerName}</strong>
                            </div>
                            <div className="col-span-5 py-0">
                                Job Name: <strong>{orderData?.OrderNumber}</strong>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gap-y-8 border-b border-slate-200">
                            <div className="col-span-7 py-0">
                                Order Date: <strong>{moment(orderData?.Date).format('DD/MM/YYYY')}</strong>

                            </div>
                            <div className="col-span-5 py-0">
                                Delivery Date: <strong>{moment(orderData?.DeliveryDate).format('DD/MM/YYYY')}</strong>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gap-y-8 border-b border-slate-200">
                            <div className="col-span-7 py-0">
                                Client: <strong>{orderData?.Client}</strong>

                            </div>
                            <div className="col-span-5 py-0">
                                Quantity: <strong>{orderData?.Quantity}</strong>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gap-y-8 border-b border-slate-200">
                            <div className="col-span-7 py-0">
                                File Name: <strong>{orderData?.FileName}</strong>
                            </div>
                            <div className="col-span-5 py-0">
                                File Size: <strong>{orderData?.FileSize}</strong>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gap-y-8 border-b border-slate-200">
                            <div className="col-span-7 py-0">

                                Work Start Date:
                            </div>
                            <div className="col-span-5 py-0">
                                Work End Date:
                            </div>
                        </div>
                    </div>

                    {renderSection()}

                </div>
            </DialogContent>
        </Dialog>
    );
};

const QrCode = ({ open, orderId }) => {
    const ref = useRef(null)

    const [options, setOptions] = useState({
        width: 120,
        height: 120,
        dotsOptions: {
            color: "black",
            type: "dots",
            gradient: {
                type: "linear",
                rotation: 0,
                colorStops: [
                    { offset: 0, color: "black" },
                    { offset: 1, color: "black" }
                ]
            }
        },
        imageOptions: {
            crossOrigin: "anonymous",
            margin: 0,
            hideBackgroundDots: true
        },
        cornersSquareOptions: {
            type: "dot",
            color: "black"
        },
        cornersDotOptions: {
            type: "dot"
        },
        data: JSON.stringify({
            OrderId: orderId,
        }),
    })
    

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            data: JSON.stringify({
                OrderId: orderId,
            }),
        }));
    }, [orderId]);


    const [qrCode] = useState(new QRCodeStyling(options));

    useEffect(() => {
        if (ref.current && open) {
            qrCode?.append(ref.current);
        }

    }, [qrCode, ref, open]);

    useEffect(() => {
        if (!qrCode) return;
        qrCode.update(options);
    }, [qrCode, options]);

    return (
        <div className="-mt-1 flex flex-wrap items-center justify-center my-2 w-full sm:max-w-sm xl:max-w-md ml-auto mr-auto">
            <div ref={ref} id={`qr-gen-${orderId}`}/>
        </div>
    )
}

export default PrintOrderForm;