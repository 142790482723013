import { Box, DialogContent, Tooltip, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { CheckCircle, Close } from '@mui/icons-material';
import ViewTable from 'components/Tables/ViewTable';
import React, { useEffect, useState } from "react";
import { dataService } from "services/data-service";
import FlowCheckItemDialog from './FlowCheckItemDialog';

function OrderFlowListDialog(props) {
    const { onClose, open, OrderDetail, viewDialogData } = props;

    const [flowStepDataList, setFlowStepDataList] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [orderBy, setOrderByData] = useState('CreatedAt');
    const [order, setOrderData] = useState('desc');
    const [offset, setOffset] = useState(10);

    const [loadingRows, setLoadingRows] = useState({});
    const [viewFlowCheckDialog, setViewFlowCheckDialog] = useState({ isVisible: false, data: null, extraData: null })

    const handleFlowStepCheckItem = async (row) => {
        setLoadingRows((prevRows) => ({
            ...prevRows,
            [row.FlowStepId]: true
        }));
        try {
            let res = await dataService('get', `FlowStepCheckItemList/${row?.FlowStepId}/${row?.ProductId}/${row?.WorkCenterId}`, '');
            if (res.status === 200 && res?.data?.length > 0) {
                setViewFlowCheckDialog({
                    isVisible: true, data: res?.data, extraData: { ...row, FileName: OrderDetail?.FileName, OrderNumber: OrderDetail?.OrderNumber }
                })
            }
            setLoadingRows((prevRows) => ({
                ...prevRows,
                [row.FlowStepId]: false
            }));
        } catch (error) {
            setLoadingRows((prevRows) => ({
                ...prevRows,
                [row.FlowStepId]: false
            }));
        }
    }

    const columns = [
        {
            id: 'WorkCenterName', numeric: false, disablePadding: true, label: 'Flow', dataType: 'component',
            Component: (row) => {
                return (
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
                        <Tooltip title={row?.IsCheckItemsChecked ? "Checklist done" : "Checklist pending"}>
                            <CheckCircle fontSize="large" color={row?.IsCheckItemsChecked ? "success" : "error"} onClick={() => handleFlowStepCheckItem(row)} />
                        </Tooltip>
                        <Typography>{row?.WorkCenterName}</Typography>
                    </Box>
                )
            }
        },
        { id: 'InDate', numeric: false, disablePadding: false, label: 'In', dataType: 'date' },
        { id: 'StartDate', numeric: false, disablePadding: false, label: 'Start', dataType: 'datetime' },
        {
            id: 'StartedBy', numeric: false, disablePadding: false, label: 'Started By', dataType: 'component',
            Component: (row) => {
                return (
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
                        <div className='!text-xs' style={{ fontWeight : "600" }}>{row?.StartedBy || (row?.StartedByDevice ? `RD-${row?.StartedByDevice}` : "-")}</div>
                    </Box>
                )   
            }
        },
        { id: 'FinishDate', numeric: false, disablePadding: false, label: 'Out', dataType: 'datetime' },
        { id: 'FinishedBy', numeric: false, disablePadding: false, label: 'Finished By', dataType: 'component',
        Component: (row) => {
            return (
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
                    <div className='!text-xs' style={{ fontWeight : "600" }}>{row?.FinishedBy || (row?.FinishedByDevice ? `RD-${row?.FinishedByDevice}` : "-")}</div>
                </Box>
            )   
        }
     },
        { id: 'QuantityIn', numeric: false, disablePadding: false, label: 'Qty In', dataType: 'string' },
        { id: 'ScrapQuantity', numeric: false, disablePadding: false, label: 'Scrap', dataType: 'string' },
        { id: 'QuantityOut', numeric: false, disablePadding: false, label: 'Qty Out', dataType: 'string' },
        { id: 'MachineId', numeric: false, disablePadding: false, label: 'Machine', dataType: 'string' },
        { id: 'OperatorId', numeric: false, disablePadding: false, label: 'Operator', dataType: 'string' },
        { id: 'HoldReason', numeric: false, disablePadding: false, label: 'Hold Reason', dataType: 'string' },

    ];

    const handleClose = () => {
        onClose(false);
    };

    useEffect(() => {
        fetchData()
    }, [page, order, orderBy, offset])

    const fetchData = async () => {
        let res = await dataService('get', `OrderFlowStepListView/${OrderDetail?.OrderId}`, `sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}`);
        setFlowStepDataList(res?.data);
        setTotalRecord(res?.totalRecord);
    }

    return (
        <Dialog onClose={handleClose} fullWidth={true} maxWidth="xl" aria-labelledby="simple-dialog-title" open={open}>
            <div className="flex flex-wrap items-center justify-between">
                {/* <DialogTitle id="form-dialog-title" >
                    <span className="text-2xl">{viewDialogData.title}</span>
                </DialogTitle> */}
                <Close className="w-6 h-auto mr-2 -mt-6 cursor-pointer" onClick={handleClose} />
            </div>

            <DialogContent>
                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="border-t border-gray-200">
                        <dl>

                            <ViewTable
                                title="Flow Steps"
                                columns={columns}
                                rows={flowStepDataList}
                                detail={OrderDetail}
                                page={page - 1}
                                total={totalRecord}
                                pagedata={setPage}
                                order={order}
                                orderBy={orderBy}
                                orderData={setOrderData}
                                orderByData={setOrderByData}
                                editAction={null}
                                setOffset={setOffset}
                                offset={offset}
                                isSearchable={false}
                                loadingRows={loadingRows}
                                isLoading={true}
                            />
                        </dl>
                    </div>
                </div>
            </DialogContent>

            {viewFlowCheckDialog.isVisible &&
                <FlowCheckItemDialog
                    disabled={true}
                    open={viewFlowCheckDialog}
                    onClose={() => {
                        setViewFlowCheckDialog({ data: null, isVisible: false, extraData: null })
                    }}
                />
            }
        </Dialog>
    );
};
export default OrderFlowListDialog;