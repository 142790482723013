import React from "react";
import { Checkbox } from '@mui/material';

function PrintOrderFormFile(props) {
    const { orderData } = props;

    return (
        <div className="w-full mt-2 uppercase text-sm	">
            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-0 px-1 font-semibold uppercase">
                            Party Fabric
                        </span>
                    </div>
                    <div className="col-span-5 py-0 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="PBDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Full:</span>
                            <span className="ml-1"><strong>{orderData?.PBFull}</strong></span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>MTR: </span>
                            <span className="ml-1"><strong>{orderData?.PBFullMtr}</strong></span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Half: </span>
                            <span className="ml-1"><strong>{orderData?.PBHalf}</strong></span>

                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>MTR: </span>
                            <span className="ml-1"><strong>{orderData?.PBHalfMtr}</strong></span>

                        </span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Total: </span>
                            <span className="ml-1"><strong>{orderData?.PBTotal}</strong> </span>

                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Total Meter: </span>
                            <span className="ml-1"><strong>{orderData?.PBTotalMtr}</strong> </span>

                        </span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-1 py-0">
                        <span className="flex items-center justify-start mr-4">
                            Remarks:</span>
                    </div>
                    <div className="col-span-11 py-0 pl-3">
                        <span className="flex items-center justify-start"><span className="ml-1">
                            {orderData?.PBRemarks}
                        </span></span>
                    </div>
                </div>
            </div>

            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-0 px-1 font-semibold uppercase">Cutting</span>
                    </div>
                    <div className="col-span-5 py-0 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="CuttingDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-4 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Patta: </span>
                            <span className="ml-1"><strong>{orderData?.CuttingPatta}</strong> </span>

                        </span>
                    </div>
                    <div className="col-span-4 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Tukda: </span>
                            <span className="ml-1"><strong>{orderData?.CuttingTukda}</strong></span>

                        </span>
                    </div>
                    <div className="col-span-4 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Table: </span>
                            <span className="ml-1"><strong>{orderData?.CuttingTable}</strong></span>

                        </span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start border-l-4">
                            <Checkbox size={'small'} checked={orderData?.TagZigZag === 1 ? true : false} name="TagZigZag" className="p-0" color="primary" />
                            <span className="ml-1">ZigZag</span>
                        </span>
                    </div>                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start">
                            <Checkbox size={'small'} checked={orderData?.TagInterlock === 1 ? true : false} name="TagInterlock" className="p-0" color="primary" />
                            <span className="ml-1">Interlock</span>
                        </span>
                    </div>
                </div>

                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-1 py-0">
                        <span className="flex items-center justify-start mr-4">
                            Remarks:</span>
                    </div>
                    <div className="col-span-11 pl-3">
                        <span className="flex items-center justify-start"><span className="ml-1">
                            {orderData?.CuttingRemarks}
                        </span></span>
                    </div>
                </div>
            </div>

            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-0 px-1 font-semibold uppercase">
                            Binding
                        </span>
                    </div>
                    <div className="col-span-5 py-0 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="BindingDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-6 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Fabric Color: </span>
                            <span className="ml-1"><strong>{orderData?.BindingsFabricColor}</strong> </span>

                        </span>
                    </div>
                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={orderData?.Bindings15 === 1 ? true : false} name="Bindings15" className="p-0" color="primary" />
                            <span className="ml-1">1.5</span></span>
                    </div>
                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={orderData?.Bindings11 === 1 ? true : false} name="Bindings11" className="p-0" color="primary" />
                            <span className="ml-1">11</span></span>
                    </div>
                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={orderData?.BindingsFull === 1 ? true : false} name="BindingsFull" className="p-0" color="primary" />
                            <span className="ml-1">Full</span></span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Kaan:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.BindingsKaan}
                                </strong>
                            </span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Patti:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.BindingsPatti}
                                </strong>
                            </span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Dori:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.BindingsDori}
                                </strong>
                            </span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Corner:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.BindingsCorner}
                                </strong>
                            </span>
                        </span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-6 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Top:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.BindingsTop || "-"}
                                </strong>
                            </span>
                        </span>
                    </div>
                    <div className="col-span-6 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Bottom:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.BindingsBottom || "-"}
                                </strong>
                            </span>
                        </span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start border-l-4">
                            <Checkbox size={'small'} checked={orderData?.BindingsDieCut === 1 ? true : false} name="TagZigZag" className="p-0" color="primary" />
                            <span className="ml-1">Die Cut</span>
                        </span>
                    </div>
                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start">
                            <Checkbox size={'small'} checked={orderData?.BindingsFoil === 1 ? true : false} name="TagInterlock" className="p-0" color="primary" />
                            <span className="ml-1">Foil</span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start">
                            <Checkbox size={'small'} checked={orderData?.BindingsTagPrinted === 1 ? true : false} name="TagInterlock" className="p-0" color="primary" />
                            <span className="ml-1">Tag Printed</span>
                        </span>
                    </div>
                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start">
                            <Checkbox size={'small'} checked={orderData?.BindingsMDF === 1 ? true : false} name="TagInterlock" className="p-0" color="primary" />
                            <span className="ml-1">MDF</span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start">
                            <Checkbox size={'small'} checked={orderData?.BindingsCappaBoard === 1 ? true : false} name="TagInterlock" className="p-0" color="primary" />
                            <span className="ml-1">Cappa Board</span>
                        </span>
                    </div>
                </div>

                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-1 py-0">
                        <span className="flex items-center justify-start mr-4">
                            Remarks: </span>
                    </div>
                    <div className="col-span-11 py-0  pl-3">
                        <span className="flex items-center justify-start"><span className="ml-1">
                            {orderData?.BindingsRemarks}
                        </span></span>
                    </div>
                </div>
            </div>

            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-0 px-1 font-semibold uppercase">Tag</span>
                    </div>
                    <div className="col-span-5 py-0 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="TagDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start">
                            <Checkbox size={'small'} checked={orderData?.TagLogo === 1 ? true : false} name="TagLogo" className="p-0" color="primary" />
                            <span className="ml-1">Logo</span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start">
                            <Checkbox size={'small'} checked={orderData?.TagCollection === 1 ? true : false} name="TagCollection" className="p-0" color="primary" />
                            <span className="ml-1">Collection</span>
                        </span>
                    </div>
                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start">
                            <Checkbox size={'small'} checked={orderData?.TagDesign === 1 ? true : false} name="TagDesign" className="p-0" color="primary" />
                            <span className="ml-1">Design</span>
                        </span>
                    </div>
                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start">
                            <Checkbox size={'small'} checked={orderData?.TagShed === 1 ? true : false} name="TagShed" className="p-0" color="primary" />
                            <span className="ml-1">Shed</span>
                        </span>
                    </div>
                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start">
                            <Checkbox size={'small'} checked={orderData?.TagInstruction === 1 ? true : false} name="TagInstruction" className="p-0" color="primary" />
                            <span className="ml-1">Instruction</span>
                        </span>
                    </div>

                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">

                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start">
                            <Checkbox size={'small'} checked={orderData?.TagSymbol === 1 ? true : false} name="TagSymbol" className="p-0" color="primary" />
                            <span className="ml-1">Symbol</span>
                        </span>
                    </div>
                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start">
                            <Checkbox size={'small'} checked={orderData?.TagSrNo === 1 ? true : false} name="TagSrNo" className="p-0" color="primary" />
                            <span className="ml-1">SrNo.</span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start">
                            <Checkbox size={'small'} checked={orderData?.TagWidth === 1 ? true : false} name="TagWidth" className="p-0" color="primary" />
                            <span className="ml-1 mr-1">Width</span>
                            <span> <strong>
                                <u>
                                    {orderData?.TagWidthValue}
                                </u>
                            </strong></span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start">
                            <Checkbox size={'small'} checked={orderData?.TaggingGSM === 1 ? true : false} name="TaggingGSM" className="p-0" color="primary" />
                            <span className="ml-1 mr-1">Weight</span>
                            <span> <strong>
                                <u>
                                    {orderData?.TaggingGSMValue}
                                </u>
                            </strong></span>
                        </span>
                    </div>
                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start">
                            <Checkbox size={'small'} checked={orderData?.TaggingRubs === 1 ? true : false} name="TaggingRubs" className="p-0" color="primary" />
                            <span className="ml-1 mr-1">Rubs</span>
                            <span> <strong>
                                <u>
                                    {orderData?.TaggingRubsValue}
                                </u>
                            </strong></span>
                        </span>
                    </div>
                </div>

                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-1 py-0">
                        <span className="flex items-center justify-start mr-4">
                            Remarks: </span>
                    </div>
                    <div className="col-span-11 py-0 pl-3">
                        <span className="flex items-center justify-start"><span className="ml-1">
                            {orderData?.TagRemarks}
                        </span></span>
                    </div>
                </div>
            </div>

            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-0 px-1 font-semibold uppercase">Photos</span>
                    </div>
                    <div className="col-span-5 py-0 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="PhotosDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-7 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Photo Size:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.PhotosSize}
                                </strong>
                            </span>
                        </span>
                    </div>
                    <div className="col-span-5 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span className="ml-1 mr-2">Page</span>
                            <span className="ml-2">
                                <Checkbox size={'small'} checked={orderData?.PhotosPage?.includes(1) ? true : false} name="PhotosPage1" className="p-0" color="primary" />
                                <span className="ml-1">1</span>
                            </span>
                            <span className="ml-4">
                                <Checkbox size={'small'} checked={orderData?.PhotosPage?.includes(2) ? true : false} name="PhotosPage2" className="p-0" color="primary" />
                                <span className="ml-1">2</span>
                            </span>
                            <span className="ml-4">
                                <Checkbox size={'small'} checked={orderData?.PhotosPage?.includes(3) ? true : false} name="PhotosPage3" className="p-0" color="primary" />
                                <span className="ml-1">3</span>
                            </span>

                            <span className="ml-4">
                                <Checkbox size={'small'} checked={orderData?.PhotosPage?.includes(4) ? true : false} name="PhotosPage4" className="p-0" color="primary" />
                                <span className="ml-1">4</span>
                            </span>
                        </span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={orderData?.PhotosLamination === 1 ? true : false} name="PhotosLamination" className="p-0" color="primary" />
                            <span className="ml-1">Lamination</span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={orderData?.PhotosColdPage === 1 ? true : false} name="PhotosColdPage" className="p-0" color="primary" />
                            <span className="ml-1">ColdPage</span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={orderData?.PhotosMatt === 1 ? true : false} name="PhotosMatt" className="p-0" color="primary" />
                            <span className="ml-1">Matt</span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={orderData?.PhotosGlossy === 1 ? true : false} name="PhotosGlossy" className="p-0" color="primary" />
                            <span className="ml-1 mr-1">Glossy</span>
                        </span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-1 py-0">
                        <span className="flex items-center justify-start mr-4">
                            Remarks: </span>
                    </div>
                    <div className="col-span-11 py-0 pl-3">
                        <span className="flex items-center justify-start"><span className="ml-1">
                            {orderData?.PhotosRemarks}
                        </span></span>
                    </div>
                </div>
            </div>

            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-0 px-1 font-semibold uppercase">Screen</span>
                    </div>
                    <div className="col-span-5 py-0 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="ScreenDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Top:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.ScreenTop}
                                </strong>
                            </span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Bottom:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.ScreenBottom}
                                </strong>
                            </span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Patti:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.ScreenPatti}
                                </strong>
                            </span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Printed:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.ScreenPrinted}
                                </strong>
                            </span>
                        </span>
                    </div>
                </div>

                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-1 py-0">
                        <span className="flex items-center justify-start mr-4">
                            Remarks: </span>
                    </div>
                    <div className="col-span-11 py-0 pl-3">
                        <span className="flex items-center justify-start"><span className="ml-1">
                            {orderData?.ScreenRemarks}
                        </span></span>
                    </div>
                </div>
            </div>

            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-0 px-1 font-semibold uppercase">Fittings</span>
                    </div>
                    <div className="col-span-5 py-0 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="FittingDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-6 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span>Step Gap:</span>
                            <span className="ml-1">
                                <strong>
                                    {orderData?.FittingStepGap}
                                </strong>
                            </span>
                        </span>
                    </div>
                    <div className="col-span-6 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span className="ml-1 mr-2">Step Page</span>
                            <span className="ml-2">
                                <Checkbox size={'small'} checked={orderData?.FittingNumber?.includes(1) ? true : false} name="PhotosPage1" className="p-0" color="primary" />
                                <span className="ml-1">1</span>
                            </span>
                            <span className="ml-4">
                                <Checkbox size={'small'} checked={orderData?.FittingNumber?.includes(2) ? true : false} name="PhotosPage2" className="p-0" color="primary" />
                                <span className="ml-1">2</span>
                            </span>
                            <span className="ml-4">
                                <Checkbox size={'small'} checked={orderData?.FittingNumber?.includes(3) ? true : false} name="PhotosPage3" className="p-0" color="primary" />
                                <span className="ml-1">3</span>
                            </span>

                            <span className="ml-4">
                                <Checkbox size={'small'} checked={orderData?.FittingNumber?.includes(4) ? true : false} name="PhotosPage4" className="p-0" color="primary" />
                                <span className="ml-1">4</span>
                            </span>
                        </span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-1 py-0">
                        <span className="flex items-center justify-start mr-4">
                            Remarks: </span>
                    </div>
                    <div className="col-span-11 py-0 pl-3">
                        <span className="flex items-center justify-start"><span className="ml-1">
                            {orderData?.FittingRemarks}
                        </span></span>
                    </div>
                </div>
            </div>
            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-0 px-1 font-semibold uppercase">Extra</span>
                    </div>
                    <div className="col-span-5 py-0 flex flex-wrap justify-end">
                        <span className="flex items-center justify-start mr-4">
                            <Checkbox size={'small'} checked={false} name="ExtraDone" className="p-0" color="primary" />
                            <span className="ml-1">Done</span></span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-2 py-0">
                        <span className="flex items-center justify-start">
                            <span>Parcel Type:</span>
                        </span>
                    </div>
                    <div className="col-span-4 py-0">
                        <span className="flex items-center justify-start mr-4">
                            <span className="ml-2">
                                <Checkbox size={'small'} checked={orderData?.Parcel === 1 ? true : false} name="Parcel" className="p-0" color="primary" />
                                <span className="ml-1">Parcel</span>
                            </span>
                            <span className="ml-2">
                                <Checkbox size={'small'} checked={orderData?.Loose === 1 ? true : false} name="Loose" className="p-0" color="primary" />
                                <span className="ml-1">Loose</span>
                            </span>
                        </span>
                    </div>
                    <div className="col-span-3 pl-2 border-l-4">
                        <span className="flex items-center justify-start">
                            <span>Challan Sequence:</span>
                        </span>
                    </div>
                    <div className="col-span-3 py-0">
                        <span className="flex items-center justify-start">
                            <span className="ml-2">
                                <Checkbox size={'small'} checked={orderData?.ChallanSequence1 === 1 ? true : false} name="ChallanSequence1" className="p-0" color="primary" />
                                <span className="ml-1">1</span>
                            </span>
                            <span className="ml-2">
                                <Checkbox size={'small'} checked={orderData?.ChallanSequence3 === 1 ? true : false} name="ChallanSequence3" className="p-0" color="primary" />
                                <span className="ml-1">3</span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="border-2">
                <div className="grid grid-cols-12 bg-slate-100">
                    <div className="col-span-7">
                        <span className="inline-block py-0 px-1 font-semibold uppercase">Remarks</span>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-2 border-t border-slate-200 px-1">
                    <div className="col-span-1 py-0">
                        <span className="flex items-center justify-start mr-4">
                            Remarks: </span>
                    </div>
                    <div className="col-span-11 py-0 pl-3">
                        <span className="flex items-center justify-start"><span className="ml-1">
                            {orderData?.Remarks}
                        </span></span>
                    </div>
                </div>
            </div>


            <div className="grid grid-cols-12 gap-2 px-1 mt-2">
                <div className="col-span-6 py-0">
                    <span className="flex items-center justify-start mr-4">
                        Party Signature
                    </span>
                </div>
                <div className="col-span-6 py-0">
                    <span className="flex items-center justify-end"><span className="ml-1">
                        Signature
                    </span></span>
                </div>
            </div>


        </div>
    );
};
export default PrintOrderFormFile;