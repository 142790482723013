// import Button from '@mui/material/Button';
import { DialogContent, Grid } from '@mui/material';
//import moment from "moment";
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
//import DialogTitle from '@mui/material/DialogTitle';
import React, {useEffect} from 'react';
import { dataService } from 'services/data-service';
import  DeliveryChallanComponent  from "./DeliveryChallanComponent";
import moment from "moment";
import { CustomButton } from "components/Common/CustomButton";

function DeilveryChallanDialog(props) {
	const { onClose, open, selectedGCOrdersList, DCDetail, onGCDialogClose, deliveryChallanId, viewOnly } = props;
	const [selectedGCOrders, setSelectedGCOrders] = React.useState(selectedGCOrdersList || []);
	const [DCDetailData, setDCDetailData] = React.useState(DCDetail || {});


	useEffect(() => {
		fetchData()
	  }, [])

	  const fetchData = async() => {
		if(selectedGCOrdersList === undefined || DCDetail === undefined){
			
			let res = await dataService('get', `DeliveryChallanGet/${deliveryChallanId}`, null);
			setDCDetailData(res?.DCDetail);
			setSelectedGCOrders(res?.DCOrders);
			
		}
	  }

	const handleClose = () => {
		onClose();
	};

	const handleSaveAndPrint = async () => {
		

		let reqData = {
			orders: selectedGCOrders,
			flowData: DCDetailData,
			device : null
		}

		
		let res = await dataService('post', `OrderDeliveryChallanSave`, reqData);
		//setShowSubmitLoading(false);
		if (res && (res.status === 200 || res.status === 304)) {
			//setMessageDialoge({ show: true, type: 'success', message: flowStepModel?.FlowStepId ? 'Order updated successfully!' : 'Order registered successfully!' });
			//setIsReload(!isReload)
			//handleClose();
			document.title = `${DCDetailData?.DCNo}-${moment(DCDetailData?.ChallanGenerateDate).format('DDMMYYYY')}`;
			window.print();
			document.title = process.env.REACT_APP_TITLE;
			onGCDialogClose();

		} else {
			//setOpen(res.message);
			//setMessageDialoge({ show: true, type: 'error', message: res?.message || 'Error occured please try again later' });
		}

		//window.print();
	}

	return (
		<Dialog fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
			<DialogContent className="p-0">
				<Grid className='w-full px-10 py-3 text-xxs text-black'>
				<Grid className='w-full flex align-center justify-center no-print mb-2'>
					<CustomButton className='bg-blue-400 mt-1 text-white no-print text-center mr-1' size="small" label="Print" onClick={(e) => {window.print();}} />
					{!viewOnly &&
							<CustomButton className='bg-blue-400 mt-1 text-white no-print text-center mr-1' size="small" label="Save & Print" onClick={handleSaveAndPrint} />
					}
					<CustomButton className='bg-blue-400 mt-1 text-white no-print text-center mr-1' size="small" label="Cancel" onClick={handleClose} />
				</Grid>
					<Grid className='mb-3'>
						<DeliveryChallanComponent selectedGCOrdersList= {selectedGCOrders} DCDetail={DCDetailData} />
					</Grid>
					{/* className='break-before-page mt-6' */}
					<Grid className={selectedGCOrders?.length > 7 ? 'break-before-page mt-6' : ''}>
						<DeliveryChallanComponent selectedGCOrdersList= {selectedGCOrders} DCDetail={DCDetailData} />
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};
export default DeilveryChallanDialog;